import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import ResetPasswordDevise from '../components/ResetPasswordDevise'

const ActivatePasswordPage = () => {

  const schemaMarkup = [
    {
      "@context": "https://schema.org",
      "@url": "https://hubblecontacts.com/reset-password",
      "@type": "webpage",
      "name": "Activate Hubble Member Portal Password | Hubble",
      "description": "Activate your account and set your password to log into the Hubble Member Portal.",
      "publisher": {
        "@type": "Organization",
        "name": "Hubble Contacts"
      }
    }
  ]

  return (
    <Layout>
      <SEO title="Activate Hubble Member Portal Password | Hubble"
        description="Activate your account and set your password to log into the Hubble Member Portal."
        schemaMarkup={schemaMarkup}/>
      <ResetPasswordDevise activate />
    </Layout>
  )
}

export default ActivatePasswordPage
